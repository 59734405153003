import React from 'react'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import { MDXProvider } from '@mdx-js/tag'
import SEO from '../SEO'
import PageWrapper from '../PageWrapper'
import PageHeader from '../PageHeader'
import PageContent from '../PageContent'


function PageTemplate({ data: { mdx } }) {
  return (
    <PageWrapper>
      <SEO title={mdx.frontmatter.title} keywords={mdx.frontmatter.tags}/>
      <PageHeader>{mdx.frontmatter.title}</PageHeader>
      <PageContent>
        <MDXProvider>
          <MDXRenderer>{mdx.code.body}</MDXRenderer>
        </MDXProvider>
      </PageContent>
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query PageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
      code {
        body
      }
    }
  }
`

export default PageTemplate